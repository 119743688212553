import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { Box, Divider, Fade, IconButton, Typography } from "@mui/material";
import React, { memo } from "react";

const Footer = ({
  numPages = 0,
  onZoomIn = () => {},
  onZoomOut = () => {},
  visible,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Fade timeout={400} in={visible}>
      <Box
        id="footer-tools"
        display={"flex"}
        justifyContent={"center"}
        zIndex={(t) => t.zIndex.modal + 12}
        position={"fixed"}
        bottom={20}
        left={0}
        right={0}
        onMouseEnter={onMouseEnter} // Ativa visibilidade
        onMouseLeave={onMouseLeave} // Permite ocultação
        onClick={(e) => e.stopPropagation()}
      >
        <Box
          borderRadius={100}
          height={45}
          bgcolor={"rgba(0,0,0,.7)"}
          fontSize={".95rem"}
          color={"#FFF"}
          px={3}
          pr={1}
          display={"flex"}
          alignItems={"center"}
        >
          <Typography mr={1} fontWeight={600}>
            {numPages} {numPages > 1 ? "páginas" : "página"}
          </Typography>
          <Divider
            sx={{ height: "100%", bgcolor: "#FFF", mx: 1, opacity: 0.3 }}
            orientation="vertical"
          />
          <IconButton onClick={onZoomIn} color="inherit">
            <ZoomIn />
          </IconButton>
          <IconButton onClick={onZoomOut} color="inherit">
            <ZoomOut />
          </IconButton>
        </Box>
      </Box>
    </Fade>
  );
};

export default memo(Footer);
