import {
    BusinessOutlined,
    ExpandLess,
    ExpandMore
} from "@mui/icons-material";
import { Box, Button, Collapse, Divider, Stack } from "@mui/material";
import React, { memo, useState } from "react";
import Baseline from "../../../../components/form/Baseline";

const MoreInfo = ({
  isEditable,
  hasPermissionToEdit,
  org = {},
  group = {},
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      {!isEditable && !hasPermissionToEdit && <Divider sx={{ my: -1 }} />}
      <Box>
        <Button
          onClick={() => setExpanded(!expanded)}
          startIcon={expanded ? <ExpandLess /> : <ExpandMore />}
        >
          {expanded ? "Mostrar menos" : "Mostrar mais"}
        </Button>
      </Box>
      <Collapse in={expanded}>
        <Stack gap={2}>
          <Stack direction={"row"}>
            <Baseline
              sx={{ flex: 1 }}
              Icon={BusinessOutlined}
              label={"Organização"}
              value={org?.name}
              emptyLabel={"Sem organização"}
            />
            <Baseline
              sx={{ flex: 1 }}
              label={"Grupo"}
              value={group?.name}
              emptyLabel={"Sem grupo"}
            />
          </Stack>
          <Stack direction={"row"}>
            <Baseline
              sx={{ flex: 1 }}
              Icon={() => {}}
              label={"Empresa"}
              value={org?.code}
              emptyLabel={"Sem empresa"}
            />
            <Baseline
              sx={{ flex: 1 }}
              label={"Filial"}
              value={org?.branch}
              emptyLabel={"Sem filial"}
            />
          </Stack>
          <Stack direction={"row"}>
            <Baseline
              sx={{ flex: 1 }}
              Icon={() => {}}
              label={"Centro"}
              value={org?.plant}
              emptyLabel={"Sem centro"}
            />
            <Baseline
              sx={{ flex: 1 }}
              label={"Centro de custo"}
              value={group?.costcenter}
              emptyLabel={"Sem centro de custo"}
            />
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
};

export default memo(MoreInfo);
