import { HistoryOutlined, NotesOutlined } from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import React, { memo } from "react";

const TabsNav = ({ tab, onChange, role, expenseId, originalValuesRef }) => {
  return (
    <Box height={40} pl={1.5}>
      <Tabs
        value={tab}
        onChange={(e, v) => onChange(v)}
        TabIndicatorProps={{
          sx: {
            height: 3,
            mb: 0,
            borderTopLeftRadius: 100,
            borderTopRightRadius: 100,
            transitionDuration: ".1s",
            transitionDelay: 0,
          },
        }}
        sx={{
          minHeight: 35,
          boxShadow: 0,
          ml: -1,
          "& .MuiTabs-root": {
            minHeight: 35,
            position: "relative",
          },
          "& .MuiTab-root": {
            minHeight: 35,
            minWidth: 0,
            fontSize: ".95rem",
            gap: "5px",
            padding: "10px 23px 10px 23px",
            "& .MuiTab-wrapper": {
              flexDirection: "row",
              gap: "10px",
            },
          },
        }}
      >
        <Tab
          value={0}
          label="Detalhes"
          icon={<NotesOutlined fontSize="small" />}
          iconPosition="start"
        />
        <Tab
          value={1}
          label={"Atividade"}
          icon={<HistoryOutlined fontSize="small" />}
          iconPosition="start"
        />
      </Tabs>
    </Box>
  );
};

export default memo(TabsNav);
