import {
  PeopleAltOutlined
} from "@mui/icons-material";
import { Box } from "@mui/material";
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Baseline from "../../../../components/form/Baseline";
import LoadingSection from "../../../../components/loading/LoadingSection";
import UserListItem from "../../../../components/USERS/UserListItem";
import {
  selectHasMultipleGroups,
  selectRequiredFields,
} from "../../../../store/features/accountSlice";
import { expenseInitialState } from "../../../../utils/initialStates";
import AmountConversionInfo from "../../components/conversion/AmountConversionInfo";
import AmounInput from "../../components/inputs/AmounInput";
import CategoryInput from "../../components/inputs/CategoryInput";
import DateInput from "../../components/inputs/DateInput";
import DistanceInput from "../../components/inputs/DistanceInput";
import GroupInput from "../../components/inputs/GroupInput";
import LocalInput from "../../components/inputs/LocalInput";
import ObsInput from "../../components/inputs/ObsInput";
import PaymentInput from "../../components/inputs/PaymentInput";
import ProjectInput from "../../components/inputs/ProjectInput";
import RoutePoliciesInput from "../../components/inputs/RoutePoliciesInput";
import AccommodationInfo from "./AccommodationInfo";
import MoreInfo from "./MoreInfo";
import VehicleInfo from "./VehicleInfo";

const variant = "outlined";

const FormContent = forwardRef(
  (
    {
      values = expenseInitialState,
      onChangeValue = () => {},
      loading,
      disabled,
      autoFocusInputs = [],
      isEditable = true,
      role,
      user = {},
      group = {},
      branch = {},
      isMatched,
      hasPermissionToEdit,
      isRoute,
    },
    ref
  ) => {
    const requiredFields = useSelector(selectRequiredFields);

    const hasMultipleGroups = useSelector(selectHasMultipleGroups);

    const [errors, setErrors] = useState({});

    const validateFields = useCallback(() => {
      const newErrors = {};
      requiredFields.forEach((field) => {
        if (!values[field] || values[field] === "") {
          newErrors[field] = "Este campo é obrigatório";
        }
      });
      setErrors(newErrors);
      // Retorna true se não houver erros
      return Object.keys(newErrors).length === 0;
    }, [requiredFields, values]);

    const clearError = useCallback(
      (inputName) => setErrors((prev) => ({ ...prev, [inputName]: null })),
      []
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          validateFields,
        };
      },
      [validateFields, values]
    );

    const handleChangeCurrency = useCallback(
      (v) => onChangeValue("currency", v),
      []
    );
    const handleChangeAmount = useCallback(
      (v) => onChangeValue("amount", v),
      []
    );
    const handleChangeCategory = useCallback(
      (v) => onChangeValue("category", v),
      []
    );
    const handleChangeDistance = useCallback(
      (v) => onChangeValue("distance", v),
      []
    );
    const handleChangeDate = useCallback((v) => onChangeValue("date", v), []);
    const handleChangeRoutePolicy = useCallback(
      (v) => onChangeValue("routePolicy", v),
      []
    );
    const handleChangePayment = useCallback(
      (v) => onChangeValue("payment", v),
      []
    );
    const handleChangeProject = useCallback(
      (v) => onChangeValue("project", v),
      []
    );
    const handleChangeNotes = useCallback((v) => onChangeValue("notes", v), []);

    const handleChangeLocal = useCallback((v) => onChangeValue("place", v), []);

    const showAllFields = useMemo(
      () => ["approver", "financial"].includes(role),
      [role]
    );

    const margin = useMemo(
      () => (isEditable ? "dense" : hasPermissionToEdit ? "none" : "dense"),
      [isEditable, hasPermissionToEdit]
    );

    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={!isEditable ? 2.5 : 1}
      >
        {loading ? (
          <>
            <LoadingSection h={"400px"} />
          </>
        ) : (
          <>
            {showAllFields && (
              <Box mb={-1}>
                <Baseline
                  label={"Usuário"}
                  Icon={PeopleAltOutlined}
                  value={
                    <UserListItem clickable={false} disableGutters dense user={user} />
                  }
                />
              </Box>
            )}
            {!isRoute && (
              <Box mb={isEditable ? 1 : 0}>
                <AmounInput
                  isEditable={isEditable}
                  autoFocus={autoFocusInputs.includes("amount")}
                  readOnly={isMatched || disabled}
                  currency={values?.currency}
                  value={values?.amount}
                  onChangeCurrency={handleChangeCurrency}
                  onChange={handleChangeAmount}
                  disabled={isMatched}
                />

                {Boolean(values?.conversionAmount) &&
                  values?.displayAmount?.currency !==
                    values?.originalAmount?.currency && (
                    <AmountConversionInfo
                      conversionAmount={values?.conversionAmount}
                      originalAmount={values?.originalAmount}
                      displayAmount={values?.displayAmount}
                    />
                  )}
              </Box>
            )}
            {isRoute && (
              <DistanceInput
                isEditable={isEditable || hasPermissionToEdit}
                readOnly={disabled}
                variant={variant}
                value={values.distance || ""}
                onChange={handleChangeDistance}
                margin={margin}
              />
            )}

            {isEditable && hasMultipleGroups && (
              <GroupInput
                isEditable={isEditable}
                enableDefaultValue
                isAccount
                value={values?.group}
                onChange={(v) => onChangeValue("group", v)}
                readOnly={disabled}
                margin={margin}
                variant={variant}
              />
            )}

            {!isRoute && (
              <>
                <CategoryInput
                  isEditable={isEditable || hasPermissionToEdit}
                  readOnly={disabled}
                  variant={variant}
                  value={values.category}
                  onChange={handleChangeCategory}
                  margin={margin}
                  required={requiredFields?.includes("category")}
                  error={!!errors.category}
                  helperText={errors.category}
                  clearError={clearError}
                  groupId={values?.group?._id}
                  disabled={hasMultipleGroups && !values?.group}
                />
                <LocalInput
                  disabled={disabled}
                  isEditable={isEditable || hasPermissionToEdit}
                  variant={variant}
                  readOnly={disabled}
                  value={values?.place}
                  onChange={handleChangeLocal}
                  categoryType={values?.category?.type}
                  margin={margin}
                  required={requiredFields?.includes("place")}
                  error={!!errors.place}
                  helperText={errors.place}
                  clearError={clearError}
                />
                {values?.category?.type === "accommodation" && (
                  <AccommodationInfo
                    dailyNumber={values?.dailyNumber}
                    dailyRate={values?.dailyRate}
                    variant={variant}
                    isEditable={isEditable || hasPermissionToEdit}
                    onChangeValue={onChangeValue}
                    margin={margin}
                    currency={values?.currency}
                  />
                )}
                {values?.category?.type === "fuel" && (
                  <VehicleInfo
                    disabled={disabled}
                    isEditable={isEditable || hasPermissionToEdit}
                    variant={variant}
                    values={{
                      vehicle: values?.vehicle,
                      fuelType: values?.fuelType,
                      liters: values?.liters,
                      odometer: values?.odometer,
                      kml: values?.kml,
                      pricePerLiter: values?.pricePerLiter,
                      fullTank: values?.fullTank,
                    }}
                    onChangeValue={onChangeValue}
                    role={role}
                    margin={margin}
                    currency={values?.currency}
                  />
                )}
              </>
            )}

            <DateInput
              isEditable={isEditable}
              readOnly={isMatched || disabled}
              variant={variant}
              value={values?.date ? new Date(values?.date) : null}
              onChange={handleChangeDate}
              disabled={isMatched}
            />

            {isRoute && (
              <RoutePoliciesInput
                isEditable={isEditable}
                readOnly={disabled}
                variant={variant}
                value={values?.routePolicy}
                onChange={handleChangeRoutePolicy}
                date={values?.date ? new Date(values?.date) : new Date()}
              />
            )}

            {!isRoute && (
              <PaymentInput
                isEditable={isEditable}
                readOnly={isMatched || disabled}
                variant={variant}
                disabled={isMatched}
                value={values.payment}
                onChange={handleChangePayment}
                refundable={values?.refundable}
                controlledRefundable={!isEditable}
                required={requiredFields?.includes("payment")}
                error={!!errors.payment}
                helperText={errors.payment}
                clearError={clearError}
              />
            )}
            <ProjectInput
              isEditable={isEditable || hasPermissionToEdit}
              readOnly={disabled}
              variant={variant}
              value={values?.project}
              onChange={handleChangeProject}
              margin={margin}
              role={role}
              disabled={hasMultipleGroups && !values?.group}
              groupId={values?.group?._id}
            />
            <ObsInput
              isEditable={isEditable || hasPermissionToEdit}
              readOnly={disabled}
              variant={variant}
              value={values.notes}
              onChange={handleChangeNotes}
              margin={margin}
              required={requiredFields?.includes("notes")}
              error={!!errors.notes}
              helperText={errors.notes}
              clearError={clearError}
            />

            {showAllFields && (
              <>
                <MoreInfo
                  isEditable={isEditable}
                  hasPermissionToEdit={hasPermissionToEdit}
                  org={branch}
                  group={group}
                />
              </>
            )}
          </>
        )}
      </Box>
    );
  }
);

export default memo(FormContent);
