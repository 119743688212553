import { Box, FormHelperText, Typography } from "@mui/material";
import React, { memo } from "react";

function Baseline({
  Icon,
  value,
  label,
  emptyLabel,
  error = "",
  sx,
  valueSx,
  iconSx,
}) {
  return (
    <Box display={"flex"} alignItems={"center"} sx={sx}>
      {Icon && (
        <Box width={40} mr={1.4} display={"flex"} justifyContent={"center"}>
          <Icon color={"action"} sx={{ fontSize: "1.4rem", ...iconSx }} />
        </Box>
      )}
      <Box flex={1}>
        <Typography
          variant="body2"
          fontSize={".8rem"}
          color={"text.secondary"}
          fontWeight={"600"}
        >
          {label}
        </Typography>
        <Typography
          maxWidth={400}
          variant="body2"
          sx={{ wordBreak: "break-word", ...valueSx }}
          fontSize={".92rem"}
          color={
            value ? (error ? "warning.main" : "text.primary") : "text.secondary"
          }
          component={"div"}
          fontWeight={value ? "600" : "400"}
        >
          {value || emptyLabel}
        </Typography>
        {error && value && (
          <FormHelperText sx={{ color: "warning.main" }}>
            {error}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
}

export default memo(Baseline);
