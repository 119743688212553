import { Box, CircularProgress, useScrollTrigger } from "@mui/material";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Document, Page } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import {
  closeElement,
  selectModalInfo,
} from "../../../store/features/base/modalsSlice";
import Error from "./components/Error";
import Footer from "./components/Footer";
import Header from "./components/Header";
import {
  DEFAULT_ZOOM,
  MAX_ZOOM,
  MIN_MOUSE_SPEED,
  MIN_ZOOM,
  ZOOM_FACTOR,
} from "./utils";

const ModalPDFViewer = () => {
  const dispatch = useDispatch();

  const timeoutRef = useRef(null);
  const lastMousePosition = useRef({ x: 0, y: 0, time: 0 });
  const [content, setContent] = useState(undefined);
  const [numPages, setNumPages] = useState();
  // const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [visibleTools, setVisibleTools] = useState(true);
  const [loading, setLoading] = useState(true);

  const { open, payload } = useSelector((state) =>
    selectModalInfo(state, "pdfViewer")
  );

  const url = useMemo(() => payload?.url || null, [payload]);
  const filename = useMemo(() => payload?.filename || null, [payload]);

  const onClose = useCallback(() => {
    dispatch(closeElement("pdfViewer"));
  }, []);

  const scrolled = useScrollTrigger({
    target: content || undefined,
    disableHysteresis: true,
    threshold: 0,
  });

  function onDocumentLoadSuccess(params) {
    setNumPages(params?.numPages);
    setLoading(false);
  }

  const handleZoomIn = useCallback(() => {
    setZoom((prev) => Math.min(prev + ZOOM_FACTOR, MAX_ZOOM));
  }, []);

  const handleZoomOut = useCallback(() => {
    setZoom((prev) => Math.max(prev - ZOOM_FACTOR, MIN_ZOOM));
  }, []);

  const renderPages = useCallback(
    (page, idx) => (
      <Page
        key={idx.toString()}
        pageNumber={idx + 1}
        className={"pdf-page"}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        scale={1}
      />
    ),
    []
  );

  const handleMouseMove = useCallback((e) => {
    const currentTime = Date.now();
    const { x: lastX, y: lastY, time: lastTime } = lastMousePosition.current;

    const deltaX = e.clientX - lastX;
    const deltaY = e.clientY - lastY;
    const deltaTime = currentTime - lastTime;

    // Verificar se o mouse está sobre o Header
    const isOverHeader = e.target.closest("#header-tools");
    const isOverFooter = e.target.closest("#footer-tools");

    if (deltaTime > 0) {
      const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2); // Distância percorrida
      const speed = (distance / deltaTime) * 1000; // Velocidade em pixels por segundo

      if (!isOverHeader && !isOverFooter && speed > MIN_MOUSE_SPEED) {
        // Mostrar ferramentas se a velocidade for maior que o limite e não estiver sobre o header
        setVisibleTools(true);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setVisibleTools(false);
        }, 2000);
      }
    }

    // Atualizar a posição do mouse
    lastMousePosition.current = {
      x: e.clientX,
      y: e.clientY,
      time: currentTime,
    };
  }, []);

  useEffect(() => {
    // Configuração inicial para ocultar ferramentas
    timeoutRef.current = setTimeout(() => {
      setVisibleTools(false);
    }, 2000);

    // Limpar timeout ao desmontar o componente
    return () => clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    if (!open) {
      setZoom(DEFAULT_ZOOM);
    }
  }, [open]);

  if (!open) return;

  return (
    <>
      <Box
        component={"div"}
        ref={(node) => node && setContent(node)}
        position={"fixed"}
        top={0}
        left={0}
        width={"100vw"}
        height={"100vh"}
        sx={{
          zIndex: (t) => t.zIndex.modal + 10,
          backdropFilter: "blur(2px)",
          overflowX: "hidden",
        }}
        bgcolor={"rgba(0,0,0,.8)"}
        overflow={"scroll"}
        onMouseMove={handleMouseMove}
        onClick={onClose}
      >
        <Header
          filename={filename}
          url={url}
          visible={visibleTools}
          scrolled={scrolled}
          onClose={onClose}
          onMouseEnter={() => {
            // Cancela ocultação e mantém visível
            clearTimeout(timeoutRef.current);
            setVisibleTools(true);
          }}
          onMouseLeave={() => {
            // Configura o timer para ocultar ao sair do header
            timeoutRef.current = setTimeout(() => {
              setVisibleTools(false);
            }, 2000);
          }}
        />
        <Box
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            bgcolor: "transparent",
            pb: 10,
            pt: 0,
          }}
        >
          <Box
            sx={{
              transform: `scale(${zoom})`,
              transformOrigin: "top center", // Ajusta o ponto de origem do zoom
              transition: "transform 0.2s ease-in-out", // Suaviza o zoom
            }}
          >
            <Document
              file={url}
              onClick={(e) => e.stopPropagation()}
              onLoadStart={() => setLoading(true)}
              noData={<Error onClose={onClose} />}
              error={<Error onClose={onClose} />}
              loading={
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={300}
                >
                  <CircularProgress sx={{ color: "#FFF" }} />
                </Box>
              }
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => console.log(error)}
              className={"pdf-document"}
              externalLinkTarget="_blank"
            >
              {Array.from(new Array(numPages), renderPages)}
            </Document>
          </Box>
        </Box>
      </Box>
      <Footer
        visible={visibleTools && !loading}
        numPages={numPages}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onMouseEnter={() => {
          // Cancela ocultação e mantém visível
          clearTimeout(timeoutRef.current);
          setVisibleTools(true);
        }}
        onMouseLeave={() => {
          // Configura o timer para ocultar ao sair do header
          timeoutRef.current = setTimeout(() => {
            setVisibleTools(false);
          }, 2000);
        }}
      />
    </>
  );
};

export default memo(ModalPDFViewer);
